/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Subtitle, Text, Button, Menu, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Svatba Plašanů"} description={"Ňuchňa & Plašan Vás srdečně zvou na svou zimní svatbu do Krkonoš!"}>
        <Column className="css-1fhpoo6 --style2 --right bg--bottom --full pt--20" name={"homepage"} style={{"paddingTop":null,"marginBottom":0,"paddingBottom":0}} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/f9b13e33cf874a90ad4467cc78e47107_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/f9b13e33cf874a90ad4467cc78e47107_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/f9b13e33cf874a90ad4467cc78e47107_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/f9b13e33cf874a90ad4467cc78e47107_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/f9b13e33cf874a90ad4467cc78e47107_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/f9b13e33cf874a90ad4467cc78e47107_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/f9b13e33cf874a90ad4467cc78e47107_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/f9b13e33cf874a90ad4467cc78e47107_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="--left el--1 mt--10 flex--center" style={{"maxWidth":1400,"paddingBottom":0}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="--center mb--80 mt--40" style={{"maxWidth":293,"paddingTop":8.416656494140625,"paddingBottom":36.96665954589844,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image className="--left pl--40" alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/51712b7212824833b658ef7cad5f50b9.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":75}} srcSet={""} content={null} position={{"x":"-27.69953051643192%","y":"-7.434782608695652%"}}>
              </Image>

              <Title className="title-box title-box--center ff--2 fs--20 w--400 ls--02 lh--12 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":207,"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null}} content={"<b><font color=\"#5864cb\">Ňuchňa &amp; Plašan<br>Vás srdečně\nzvou\n<br>na svou <br>zimní svatbu!<br><br>13. února 2021<br>v 11:30 hod</font></b><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30" name={"zenich_a_nevesta"} style={{"marginTop":0,"paddingTop":34,"paddingBottom":null,"backgroundColor":"var(--color-variable-2)"}} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/54ed53a0b6ba4913898690a4c3575fc2_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/7361/54ed53a0b6ba4913898690a4c3575fc2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/54ed53a0b6ba4913898690a4c3575fc2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/54ed53a0b6ba4913898690a4c3575fc2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/54ed53a0b6ba4913898690a4c3575fc2_s=1400x_.jpg 1400w"} content={null} position={{"x":"1.27675647831893e-15%","y":"0.3773584905660392%"}}>
              </Image>

              <Title className="title-box ff--1 fs--36" content={"Kristýna Vyčítalová"}>
              </Title>

              <Subtitle className="subtitle-box ff--1 fs--14" content={"nevěsta"}>
              </Subtitle>

              <Text className="text-box ff--1 fs--18 w--300" style={{"maxWidth":392}} content={"Je máma dospělých dvojčat, Šimona a Matouše. <br>Miluje své děti, svou rodinu, svou koloběžku, sníh, přírodu, hory, výlety, štětec a barvy, své rodné město - Plzeň, českou historii, teplé kakao, krupicovou kaši a hořkou čokoládu. <br>Pracuje v marketingu a nesnáší trapnou reklamu :-)<br>Za osudné setkání s Plašanem může nejméně oblíbený software firmy - Pepa2 :-D<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/9d84d95f45a94c1cb7de8a456db818d1_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/7361/9d84d95f45a94c1cb7de8a456db818d1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/9d84d95f45a94c1cb7de8a456db818d1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/9d84d95f45a94c1cb7de8a456db818d1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/9d84d95f45a94c1cb7de8a456db818d1_s=1400x_.jpg 1400w"} content={null}>
              </Image>

              <Title className="title-box ff--1 fs--36" content={"Vojtěch Plachý"}>
              </Title>

              <Subtitle className="subtitle-box ff--1 fs--14" content={"ženich"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":380}} content={"Je vývojář, softwarový architekt, lyžařský instruktor a milovník zimních hor, počítačových her a hlavně znalec piva. Svého Ňuchňu poznal na své IT štaci právě ve městě piva. Je to typický \"ajťák\", takže nepočítejte s obvyklými sociálními vlastnostmi. Tzn. neobjímat, nelíbat, nedávat pac a bude s vámi spokojeně povídat, dokud nedojde pivo! <br>I přesto...je to vítací typ :-D<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-kqf2ek pb--30 pt--30" name={"zasnoubeni"} style={{"marginTop":0}} layout={"l4"} css={css`
      
    background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/a2a7f33a53b5421ebf0c4197a1cb5a87_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/a2a7f33a53b5421ebf0c4197a1cb5a87_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/a2a7f33a53b5421ebf0c4197a1cb5a87_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/a2a7f33a53b5421ebf0c4197a1cb5a87_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/a2a7f33a53b5421ebf0c4197a1cb5a87_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/a2a7f33a53b5421ebf0c4197a1cb5a87_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/a2a7f33a53b5421ebf0c4197a1cb5a87_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/a2a7f33a53b5421ebf0c4197a1cb5a87_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1148,"marginTop":0,"paddingTop":0,"paddingBottom":20.35833740234375}} columns={"1"}>
            
            <ColumnWrapper className="mt--0 pt--0" style={{"marginTop":null,"paddingTop":null}}>
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/c7b0ea74fb114b83b4de8887b50c3f87.svg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":70}} srcSet={""} content={null} svgColor={"rgba(255,255,255,1)"}>
              </Image>

              <Title className="title-box ff--1 fs--36 mt--02" style={{"marginTop":null}} content={"<font color=\"#ffffff\">Zasnoubení</font>"}>
              </Title>

              <Title className="title-box ff--1 fs--20 w--400 ls--01 lh--14 mt--10 pt--0" style={{"maxWidth":734,"marginTop":null,"paddingTop":null}} content={"<font color=\"#21194c\">Proběhlo zimního večera v předposlední den v roce 2019, uprostřed zaváté louky a zasněžených smrčků usínajících Krkonoš. Nebe barvily něžné červánky a jejich světlo se letmo odráželo ve sněhových závějích. Byla tu s námi naše stará známá, dřevěná Kolínská bouda a na obzoru paní Sněžka.&nbsp;</font>"}>
              </Title>

              <Image className="mt--20" alt={""} src={"https://cdn.swbpg.com/t/7361/a96023c97c18435db5e8318c37f2d199_s=660x_.jpg"} svg={false} sizes={"100vw"} style={{"maxWidth":216,"marginTop":null}} srcSet={"https://cdn.swbpg.com/t/7361/a96023c97c18435db5e8318c37f2d199_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/a96023c97c18435db5e8318c37f2d199_s=660x_.jpg 660w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"bn47ts767pb"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"maxWidth":1464}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":753}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/b6695e0a626a44bb9c3fa639f8535105.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} content={null}>
              </Image>

              <Subtitle className="subtitle-box ff--3" content={"Svatební obřad<br>"}>
              </Subtitle>

              <Text className="text-box ff--1 fs--18" style={{"maxWidth":472}} content={"Proběhne na náměstí Republiky v Plzni, v budově plzeňské<br>renesanční radnice v sobotu 13. února 2021 v<br>"}>
              </Text>

              <Text className="text-box ff--1 fs--26 w--500" style={{"maxWidth":402}} content={"11:30 hodin<br>a to koronaviru navzdory!<br>"}>
              </Text>

              <Text className="text-box ff--1" style={{"maxWidth":484}} content={"Vzhledem k aktuálním omezením, <br>tj. přítomnosti maximálně 15 lidí na obřadu,<br>se nám do oddací síně vleze sotva rodina.<br>Proto vás srdečně zveme do \"Strouhy\" po 12 hod., <br>kde je možnost se v klidu na čerstvém vzduchu potkat,\nvyfotit se, <br>spáchat několik přestupků ve sdružování více <br>než 2 osob\n\nz jiné domácnosti na veřejnosti, atd...\n<br>S těmi odvážnějšími, kteří přijmou naše pozvání a nebojí se,\n\nmůžeme pokračovat dále na drobné občerstvení <br>a dort... :-D\n\n<br>Těšíme se na všechny, kteří nás přijdou podpořit!<br>Lásku a víru v lepší zítřky vir ještě nezahubil :-D ;-)<br>"}>
              </Text>

              <Button className="btn-box" href={""} target={null} content={"Děkujeme všem, kteří strávili náš den s námi!"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":788}}>
              
              <Image className="--center" alt={""} src={"https://cdn.swbpg.com/t/7361/86cab0e3c6a64f2a888c1156a5ac9c2b_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":676}} srcSet={"https://cdn.swbpg.com/t/7361/86cab0e3c6a64f2a888c1156a5ac9c2b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/86cab0e3c6a64f2a888c1156a5ac9c2b_s=660x_.jpg 660w"} content={null} position={{"x":"-84.41509433962264%","y":"-73.49056603773585%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-mq623w bg--top pb--40 pt--40" name={"program_svatby"} style={{"paddingBottom":null}} layout={"l10"} css={css`
      
    background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/ebe795af49f84086824ea1589ddede78_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/ebe795af49f84086824ea1589ddede78_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/ebe795af49f84086824ea1589ddede78_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/ebe795af49f84086824ea1589ddede78_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/ebe795af49f84086824ea1589ddede78_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/ebe795af49f84086824ea1589ddede78_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/ebe795af49f84086824ea1589ddede78_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(74,144,226,0.35), rgba(74,144,226,0.35)), url(https://cdn.swbpg.com/t/7361/ebe795af49f84086824ea1589ddede78_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center pb--20 pt--20" style={{"paddingTop":null,"paddingBottom":null}}>
              
              <Text className="text-box ff--1 fs--36 w--500" style={{"maxWidth":1636}} content={"<font color=\"#ffffff\"><br><br><br><br><br><br><br>„V životě je možné potkat se tisíckrát a nenajít… <br>a potkat se jednou a najít se navždy.“<br><br><br></font>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu pb--25 pt--30" menu={true} name={"paticka"}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box ff--3 fs--26" content={"Svatba Plašanů<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 ff--3" href={"#homepage"} target={""} content={"Zpět na začátek"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 ff--3" href={"/fotografie"} target={"_blank"} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 ff--3" href={"/misto-svatby"} target={"_blank"} content={"Místo svatby"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 ff--3" href={"/kontakt"} target={"_blank"} content={"Kontakty a mapy"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}